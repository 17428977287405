import * as React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const Activity = ({ title, description, path, featuredImage, featuredAlt }) => (
    <div className="card activity-card activity-card-detailed">
        <Link to={ path }>
            <div className="row details">
                <div className="col-xs-12 col-sm-4 col-lg-3">
                    <GatsbyImage image={ featuredImage }
                         alt={ featuredAlt } />
                </div>
                <div className="col-xs-12 col-sm-8 col-lg-9">
                    <div className=" card-body text-left">
                        <h2 className="card-title">{ title }</h2>
                        <p className="card-text">{ description }</p>
                    </div>
                </div>
            </div>
        </Link>
    </div>
);

export default Activity;