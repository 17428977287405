import * as React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Activity from "../components/activity";

export const AllActivitiesQuery = graphql`
  query AllActivities {
  allMarkdownRemark (sort: {order: DESC, fields: frontmatter___date}) {
    edges {
      node {
        frontmatter {
          activityId
          title
          description
          path
          featuredImage {
            childImageSharp {
             gatsbyImageData(
               width: 450
               placeholder: BLURRED
               formats: [AUTO, WEBP, AVIF]
             )
            }
          }
          featuredAlt
        }
      }
    }
  }
}
`
const ActivitiesPage = ({ data }) => (
    <Layout>
        <Seo title="Screen-free activities for young kids" />

        <section className="bg-primary text-center main" id="main">
            <div className="container">
                <h1>Screen-free activities for young kids</h1>
                <p>Engaging activities you can put together in a few minutes using natural elements, craft supplies and things you probably have around the house.</p>

                <hr className="star-dark" />

                {
                    data.allMarkdownRemark.edges.map(activity => {
                        const { activityId, title, description, path, featuredAlt } = activity.node.frontmatter;

                        return (
                            <Activity
                                key={activityId}
                                title={title}
                                description={description}
                                path={path}
                                featuredImage={getImage(activity.node.frontmatter.featuredImage)}
                                featuredAlt={featuredAlt}
                            />
                        )
                    })
                }
            </div>
        </section>
    </Layout>
);

export default ActivitiesPage